<template>
  <section>
    <div class="section-bg-background-color cover-image">
      <div class="container">

        <!-- /.row -->
        <div class="row no-gutters row-deck text-white">
          <div class="col-md-4">
            <div class="p-5 bg-dark-transparent01">
              <div class="card-body text-center">
                <div class="widgets-cards-icons">
                  <div class="wrp counter-icon1">
                    <img src="sources/images/ws-i/flash.png" alt="">
<!--                    <i class="fa fa-lightbulb-o"></i>-->
                  </div>
                </div>
                <h6 class="card-title mb-4">Hız</h6>
                <p class="text-white-80">Artık hiçbirimizin zamanı yok! Bu sebeple websitesine girdiklerinde kullanıcılarınızın beklediği her salise onları kaçıracak bir etken haline gelmiş bulunmakta. Bunu önlemek için websitenizde her daim en son teknolojileri kullanarak en hızlı şekilde çalışması için elimizden geleni yapıyoruz.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-5 mt-5 mt-md-0">
              <div class="card-body text-center">
                <div class="widgets-cards-icons">
                  <div class="wrp counter-icon1">
                    <img src="sources/images/ws-i/security.png" alt="">
<!--                    <i class="fe fe-thumbs-up"></i>-->
                  </div>
                </div>
                <h6 class="card-title  mb-4">Güvenlik</h6>
                <p class="text-white-80">Bilgi çağında en büyük hırsızlık elbette bilgi hırsızlığı. Herkes verileri hakkında endişe duyabilir. Ancak simeray ile çalışıyorsanız içiniz rahat olsun. Projelerinizdeki girdi alanlarını ve arkaplandaki sahtecilikleri önleyebilmek için elimizden geleni yapıyoruz.</p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-5 mt-5 mt-md-0 bg-dark-transparent01">
              <div class="card-body text-center">
                <div class="widgets-cards-icons">
                  <div class="wrp counter-icon1">
                    <img src="sources/images/ws-i/design.png" alt="">
<!--                    <i class="fe fe-headphones"></i>-->
                  </div>
                </div>
                <h6 class="card-title  mb-4">Tasarım</h6>
                <p class="text-white-80">İnsanların estetik görünüme bu kadar önem verdikleri bir çağda eski bir tasarım düşünülemez. İster sadece bir kişinin göreceği bir sayfa olsun, ister binlerce kişinin göreceği bir sayfa. İkisine de en iyisi olacak şekilde özeniyoruz. Bizim için fark yok!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "QualityStandarts"
}
</script>

<style scoped>
.counter-icon1{
  padding: 0.6rem!important;
  width: 5rem;
  height: 5rem;
}
</style>