<template>
  <section class="sptb bg-white">
    <div class="container">
      <div class="section-title center-block text-center">
        <h2><strong class="simeray">Simeray</strong><strong> Blog</strong></h2>
        <p>Sizin için özenle hazırlanmış içerikler. Düzenli olarak takip etmek için abone olabilirsiniz.</p>
      </div>
      <div class="row news">

        <div class="col-lg-4 col-md-6 col-xl-4">
          <div class="card mb-xl-0">
            <div class="item7-card-img">
              <a href="#"></a>
              <img src="https://cdn.omeraydinoglu.com/wp-content/uploads/2021/06/pexels-roger-brown-5126268-750x500.jpg" alt="img" class="cover-image">
            </div>
            <div class="card-body p-4">
              <div class="item7-card-desc d-flex mb-2">
                <a href="#"><i class="fa fa-calendar-o mr-2"></i>13 Haziran 2021</a>
                <div class="ml-auto">
                  <a href="#"><i class="fa fa-comment-o mr-2"></i>4 Yorum</a>
                </div>
              </div>
              <a href="blog-details.html" class="text-dark"><h3 class="font-weight-semibold">Bitcoin'in Özellikleri Nelerdir?</h3></a>
              <p>Merkezi Değildir  Bitcoin bir merkez tarafından kontrol edilemez. Madencilik yapan ve işlem... </p>
              <div class="d-flex align-items-center pt-2 mt-auto">
                <img src="https://media-exp2.licdn.com/dms/image/C4D03AQFpM8gmG-DcFA/profile-displayphoto-shrink_800_800/0/1634827292429?e=1660176000&v=beta&t=VnwDJFV_-V3n2u9oW8tgRvOCtxr2jVocHFMrtLEqAYg" class="avatar brround avatar-md mr-3" alt="avatar-img">
                <div>
                  <a href="profile.html" class="text-default">Ömer AYDINOĞLU</a>
                </div>
                <div class="ml-auto text-muted">
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fe fe-heart mr-1"></i></a>
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fa fa-thumbs-o-up"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xl-4">
          <div class="card mb-xl-0">
            <div class="item7-card-img">
              <a href="#"></a>
              <img src="https://cdn.omeraydinoglu.com/wp-content/uploads/2021/06/pexels-worldspectrum-844125-750x500.jpg" alt="img" class="cover-image">
            </div>
            <div class="card-body p-4">
              <div class="item7-card-desc d-flex mb-2">
                <a href="#"><i class="fa fa-calendar-o mr-2"></i>12 Haziran 2021</a>
                <div class="ml-auto">
                  <a href="#"><i class="fa fa-comment-o mr-2"></i>2 Yorum</a>
                </div>
              </div>
              <a href="blog-details.html" class="text-dark"><h3 class="font-weight-semibold">Bitcoin'in Kurucusu Kimdir?</h3></a>
              <p>Satoshi Nakamoto adlı bir yazılım geliştiricisi, matematik tabana dayalı bir elektronik ödeme...</p>
              <div class="d-flex align-items-center pt-2 mt-auto">
                <img src="https://media-exp2.licdn.com/dms/image/C4D03AQFpM8gmG-DcFA/profile-displayphoto-shrink_800_800/0/1634827292429?e=1660176000&v=beta&t=VnwDJFV_-V3n2u9oW8tgRvOCtxr2jVocHFMrtLEqAYg" class="avatar brround avatar-md mr-3" alt="avatar-img">
                <div>
                  <a href="profile.html" class="text-default">Ömer AYDINOĞLU</a>
                </div>
                <div class="ml-auto text-muted">
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fe fe-heart mr-1"></i></a>
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fa fa-thumbs-o-up"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xl-4">
          <div class="card mb-0">
            <div class="item7-card-img">
              <a href="#"></a>
              <img src="https://cdn.omeraydinoglu.com/wp-content/uploads/2021/06/pexels-worldspectrum-844124-750x500.jpg" alt="img" class="cover-image">
            </div>
            <div class="card-body p-4">
              <div class="item7-card-desc d-flex mb-2">
                <a href="#"><i class="fa fa-calendar-o mr-2"></i>11 Haziran 2021</a>
                <div class="ml-auto">
                  <a href="#"><i class="fa fa-comment-o text-muted mr-2"></i>8 Yorum</a>
                </div>
              </div>
              <a href="blog-details.html" class="text-dark"><h3 class="font-weight-semibold">Bitcoin'i Nereden Alıp Satabiliriz?</h3></a>
              <p>Bitcoin’in kendisi bile büyük merak konusuyken artık insanlar nereden Bitcoin alıp satabileceklerini...</p>
              <div class="d-flex align-items-center pt-2 mt-auto">
                <img src="https://media-exp2.licdn.com/dms/image/C4D03AQFpM8gmG-DcFA/profile-displayphoto-shrink_800_800/0/1634827292429?e=1660176000&v=beta&t=VnwDJFV_-V3n2u9oW8tgRvOCtxr2jVocHFMrtLEqAYg" class="avatar brround avatar-md mr-3" alt="avatar-img">
                <div>
                  <a href="profile.html" class="text-default">Ömer AYDINOĞLU</a>
                </div>
                <div class="ml-auto text-muted">
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fe fe-heart mr-1"></i></a>
                  <a href="" class="icon d-none d-md-inline-block ml-3"><i class="fa fa-thumbs-o-up"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row mt-3">
        <div class="col-md-12 text-center">
          <a href="#" class="btn ripple  btn-primary mb-sm-0">Tüm İçerikler</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LastPosts"
}
</script>

<style scoped>

</style>