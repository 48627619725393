import { createStore } from 'vuex'

export default createStore({
  state: {
    counter: {
      totalServices: '4.275',
      totalProjects: '12.758',
      totalDevelopers: '457',
      happyUsers: '1.852',
    },
    services: {
      0:{
        name: 'Handyman Website - İnşaat Websitesi',
        slug: 'handyman-website-insaat-websitesi',
        image: 'https://updates.theme-fusion.com/wp-content/uploads/2021/12/preview_handyman-600x450.jpg',
        price: '1.499 TL',
        desc: 'Harika bir inşaat sitesi. Almamak için kendimi zor tuttum gerçekten.',
        sales: 35,
        star: 5,
        tag: 'WordPress'
      },
      1:{
        name: 'Extreme Sports - Spor Mağaza Websitesi',
        slug: 'extreme-sports-spor-magaza-websitesi',
        image: 'https://updates.theme-fusion.com/wp-content/uploads/2021/11/preview_extreme_sports-600x450.jpg',
        price: '2.499 TL',
        desc: 'Harika bir Spor mağaza sitesi. Almamak için kendimi zor tuttum gerçekten.',
        sales: 7,
        star: 4,
        tag: 'WordPress'
      },
      2:{
        name: 'Digital Agency Website - Dijital Ajans Websitesi',
        slug: 'digital-agency-website-dijital-ajans-websitesi',
        image: 'https://updates.theme-fusion.com/wp-content/uploads/2021/08/preview_avada_digial_agency-600x450.jpg',
        price: '1.299 TL',
        desc: 'Harika bir Dijital Ajans sitesi. Almamak için kendimi zor tuttum gerçekten.',
        sales: 19,
        star: 5,
        tag: 'WordPress'
      },
      3:{
        name: 'Psychology Website - Psikoloji Websitesi',
        slug: 'psychology-website-psikoloji-websitesi',
        image: 'https://updates.theme-fusion.com/wp-content/uploads/2021/01/preview_psychology-600x450.jpg',
        price: '1.499 TL',
        desc: 'Harika bir Psikoloji sitesi. Almamak için kendimi zor tuttum gerçekten.',
        sales: 23,
        star: 5,
        tag: 'WordPress'
      },
      4:{
        name: 'Fitness Website - Fitness Websitesi',
        slug: 'fitness-website-fitness-websitesi',
        image: 'https://updates.theme-fusion.com/wp-content/uploads/2020/03/fitness_thumbnail-600x450.jpg',
        price: '1.499 TL',
        desc: 'Harika bir Fitness sitesi. Almamak için kendimi zor tuttum gerçekten.',
        sales: 5,
        star: 5,
        tag: 'WordPress'
      },
    },
    service: {
      name: 'Handyman Website - İnşaat Websitesi',
      slug: 'handyman-website-insaat-websitesi',
      image: 'https://themeforest.img.customer.envatousercontent.com/files/389560232/screenshots/00-Preview.__large_preview.jpg?auto=compress%2Cformat&q=80&fit=crop&crop=top&max-h=8000&max-w=590&s=0fd0d5642e8f4e951d2767b06fd88d0a',
      content: '<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p><p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p><p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p><p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>',
      price: '1.499 TL',
      desc: 'Harika bir inşaat sitesi. Almamak için kendimi zor tuttum gerçekten.',
      sales: 35,
      star: 5,
      tag: 'WordPress'
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
