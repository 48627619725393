<template>
  <section>
    <div class="bannerimg" style="padding: 2rem 0 2rem 0;">
      <div class="header-text mb-0">
        <div class="container">
          <div class="text-center text-white">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageHero"
}
</script>

<style scoped>

</style>