<template>
  <section>
    <div class="cover-image about-widget sptb bg-background-color" data-image-src="assets/images/banners/banner3.jpg">
      <div class="content-text mb-0">
        <div class="container">
          <div class="text-center text-white ">
            <h2 class="mb-2 font-weight-normal"><strong>Hemen Simeray'a Katılın</strong></h2>
            <p>Şimdi Simeray'a katılın ve size özel binlerce hizmet arasından dilediğinizi seçin, hemen kullanmaya başlayın.</p>
            <div class="mt-5">
              <a href="#" class="btn ripple  btn-lg btn-secondary"><i class="fa fa-plus"></i> Şimdi Katıl!</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "JoinSimeray"
}
</script>

<style scoped>

</style>