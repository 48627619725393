import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "about-us" */ '@/views/Pages/AboutUs.vue')
  },
  {
    path: '/category/:category',
    name: 'CategoryPosts',
    component: () => import(/* webpackChunkName: "category/details" */ '@/views/Blog/CategoryPosts.vue')
  },
  {
    path: '/tag/:tag',
    name: 'TagPosts',
    component: () => import(/* webpackChunkName: "tag/details" */ '@/views/Blog/TagPosts.vue')
  },
  {
    path: '/author/:author',
    name: 'AuthorPosts',
    component: () => import(/* webpackChunkName: "author/details" */ '@/views/Blog/AuthorPosts.vue')
  },
  {
    path: '/blog',
    name: 'BlogList',
    component: () => import(/* webpackChunkName: "blog" */ '@/views/Blog/BlogList.vue')
  },
  {
    path: '/blog/:post',
    name: 'BlogPost',
    component: () => import(/* webpackChunkName: "blog/post" */ '@/views/Blog/BlogPost.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contact-us" */ '@/views/Pages/ContactUs.vue')
  },
  {
    path: '/pages/terms-of-use',
    name: 'PagesTermsOfUse',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '@/views/Pages/PagesTermsOfUse.vue')
  },
  {
    path: '/pages/privacy-policy',
    name: 'PagesPrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/Pages/PagesPrivacyPolicy.vue')
  },
  {
    path: '/pages/distance-selling-contract',
    name: 'PagesDistanceSellingContract',
    component: () => import(/* webpackChunkName: "distance-selling-contract" */ '@/views/Pages/PagesDistanceSellingContract.vue')
  },
  {
    path: '/pages/cancellation-and-refund-policy',
    name: 'PagesCancellationAndRefundPolicy',
    component: () => import(/* webpackChunkName: "cancellation-and-refund-policy" */ '@/views/Pages/PagesCancellationAndRefundPolicy.vue')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects/Projects.vue')
  },
  {
    path: '/auth/login',
    name: 'AuthLogin',
    component: () => import(/* webpackChunkName: "auth/login" */ '@/views/Auth/AuthLogin.vue')
  },
  {
    path: '/auth/register',
    name: 'AuthRegister',
    component: () => import(/* webpackChunkName: "auth/register" */ '@/views/Auth/AuthRegister.vue')
  },
  {
    path: '/auth/forgot',
    name: 'AuthForgot',
    component: () => import(/* webpackChunkName: "auth/forgot" */ '@/views/Auth/AuthForgot.vue')
  },
  {
    path: '/services',
    name: 'ServicesList',
    component: () => import(/* webpackChunkName: "services" */ '@/views/Services/ServicesList.vue')
  },
  {
    path: '/service/:service',
    name: 'ServicesDetails',
    component: () => import(/* webpackChunkName: "service/details" */ '@/views/Services/ServicesDetails.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
