<template>
  <HeadContainer>
    <HomeHero />
  </HeadContainer>

  <RandomServices />

  <QualityStandarts />

  <LastServices />

  <Counter />

  <Comments />

  <JoinVia />

  <LastPosts />

<!--  Mobile Apps -->

  <JoinSimeray />

  <Newsletter />

</template>

<script>

import Counter from "@/components/Widgets/Counter";
import Newsletter from "@/components/Widgets/Newsletter";
import QualityStandarts from "@/components/Widgets/QualityStandarts";
import JoinSimeray from "@/components/Widgets/JoinSimeray";
import LastPosts from "@/components/Widgets/LastPosts";
import RandomServices from "@/components/Widgets/RandomServices";
import NewlyAdded from "@/components/Widgets/NewlyAdded";
import LastServices from "@/components/Widgets/LastServices";
import JoinVia from "@/components/Widgets/JoinVia";
import Comments from "@/components/Widgets/Comments";
import HeadContainer from "@/components/System/HeadContainer";
import HomeHero from "@/components/Hero/HomeHero";
export default {
  name: "Home",
  components: {
    HomeHero,
    HeadContainer,
    Comments,
    JoinVia,
    LastServices,
    NewlyAdded,
    RandomServices,
    LastPosts,
    JoinSimeray,
    QualityStandarts,
    Newsletter,
    Counter
  },
  mounted () {
    this.$root.scrollToTop();
  }
}
</script>

<style scoped>

</style>