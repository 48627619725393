<template>
  <section class="sptb">
    <div class="container ">
      <div class="row marketplace-section">
        <div class="col-md-6">
          <div class="card mb-0 service-widgets1">
            <div class="text-center card-body">
              <img src="sources/images/customervia.png" class="img-fluid">
              <h3 class="mt-3 mb-2"><strong>Kullanıcı Hesabı</strong></h3>
              <p>Binlerce hizmet arasından size veya şirketinize ait olan hizmeti seçerek hızlıca aktifleştirin. Siz arkanıza yaslanıp isteklerinizin gerçekleşmesini bekleyin. Sorunlarla uğraşmak yerine işinize vakit ayırın. Simeray kalitesinin farkına varın. </p>
              <a href="#" class="btn ripple  btn-secondary">Kullanıcı Paneli</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card mb-lg-0 service-widgets1">
            <div class="text-center card-body">
              <img src="sources/images/developervia.png" class="img-fluid">
              <h3 class="mt-3 mb-2"><strong>Geliştirici Hesabı</strong></h3>
              <p>Geliştirici hesabı oluşturarak Simeray'ın geliştiricilere özel sunduğu birbirinden faydalı API hizmetlerini ve <strong>ÜCRETSİZ</strong> araçlarını kullanmaya başlayabilirsiniz. Projelerinizi geliştirirken birçok faydalı API kullanarak geliştirme sürecini hızlandırabilirsiniz.</p>
              <a href="#" class="btn ripple  btn-primary">Geliştirici Portalı</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "JoinVia"
}
</script>

<style scoped>

</style>