<template>
<!--  <Loader />-->

  <router-view/>

  <Footer />
  <BackToTop />

</template>

<script>
import Loader from "@/components/System/Loader";
import HeadContainer from "@/components/System/HeadContainer";
import HomeHero from "@/components/Hero/HomeHero";
import PageHero from "@/components/Hero/PageHero";
import BackToTop from "@/components/System/BackToTop";
import Footer from "@/components/System/Footer";

export default {
  name: "App",
  components: {
    Footer,
    BackToTop,
    Loader,
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
}
</script>

<style scoped>

</style>