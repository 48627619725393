<template>
  <section class="sptb bg-white">
    <div class="container">
      <div class="section-title center-block text-center">
        <h2><strong>Kullanıcı Yorumları</strong></h2>
        <p>Simeray ile çalışıp mutlu olan yüzlerce referansımızdan sadece birkaçı. Bizim en büyük referansımız hizmetlerimiz. Hemen katılın ve sizde yerinizi alın!</p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="card mb-xl-0">
            <div class="card-body">
              <div class="team-section text-center">
                <div class="team-img">
                  <img src="sources/images/reviews/b5006d3f-d096-419b-a71d-13311f09f18d.png" class="img-thumbnail rounded-circle alt=" alt="img">
                </div>
                <h3 class="dark-grey-text mt-4">ArkeMedia</h3>
                <h6 class="text-default font-weight-normal mb-3">Kurumsal Kullanıcı</h6>
                <div class="starColor mb-3">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
                <p class="font-weight-normal text-muted mb-0">
                  <i class="fa fa-quote-left pr-2"></i>Simeray ile güzel bir çalışmamız oldu. Projeyi çok kısa sürede kavrayıp bütün beklentilerimizi fazlasıyla karşıladılar. Ufak bir revize istediğimizde ise baştan başlayıp halihazırda genel olarak iyi görünen yapıyı sıfırdan daha da güzel ve sağlam bir şekilde tekrardan kodladılar. Şimdiye kadar çalıştığım en iyi yazılım ekibi diyebilirim. Kesinlikle tavsiye ederim.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card mb-xl-0">
            <div class="card-body">
              <div class="team-section text-center">
                <div class="team-img">
                  <img src="sources/images/reviews/d13fd13d-08fa-485c-a130-1662e0a01179.jpg" class="img-thumbnail rounded-circle alt=" alt="img">
                </div>
                <h3 class=" dark-grey-text mt-4">Hasan Erbilen</h3>
                <h6 class="text-default mb-3 font-weight-normal">Bireysel Kullanıcı</h6>
                <div class="starColor mb-3">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
                <p class="font-weight-normal text-muted mb-0">
                  <i class="fa fa-quote-left pr-2"></i>Simeray ekibine çok teşekkür ederim. İşi aldığı gibi hemen başladılar çalışmalara ve yılların verdiği bilgi birikimi sayesinde hemencecik bitirdiler işi. Bir iki tane hatalı bilgi vermiştim bunlara rağman tekrar düzenleme yaptılar ve doğru bir şekilde teslim ettiler. Herşeyi sorunsuz teslim aldım teşekkür ederim ❤️

                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card mb-0">
            <div class="card-body">
              <div class="team-section text-center">
                <div class="team-img">
                  <img src="sources/images/reviews/18d36031-d194-4a6d-9681-dbff2b306a8d.jpg" class="img-thumbnail rounded-circle alt=" alt="img">
                </div>
                <h3 class="dark-grey-text mt-4">kleopatra</h3>
                <h6 class="text-default font-weight-normal mb-3">Bireysel Kullanıcı</h6>
                <div class="starColor mb-3">
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                  <i class="fa fa-star"></i>
                </div>
                <p class="font-weight-normal text-muted mb-0">
                  <i class="fa fa-quote-left pr-2"></i>Kırk yılda bir şansım yaver gitti ve Simeray'ın çalışmasıyla, sorunlu olan bir wp site taşıma işlemini başarıyla sonuçlandırdılar. Bu aşamada Taşıma işlemi dışında, web sitesindeki hataları da gecenin bir vaktinde çözmeleri, ekibin kendi işine verebileceği bir önemseyişti. Emekleriniz için teşekkür ederim. . Tekrar tekrar çalışmak ümidiyle..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Comments"
}
</script>

<style scoped>

</style>