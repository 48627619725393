<template>
  <a href="#top" id="back-to-top" ><i class="fa fa-long-arrow-up"></i></a>
</template>

<script>
export default {
  name: "BackToTop"
}
</script>

<style scoped>

</style>