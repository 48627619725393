<template>
  <section>
    <div class="sptb-2" style="padding-top: 0;">
      <div class="header-text mb-0">
        <div class="container">
          <div class="search-background bg-transparent">

            <div class="text-center text-white mb-6">
              <h1 class="mb-1"><strong>Tüm İhtiyaçlarınız için Tek Adres!</strong></h1>
              <p class="mb-0">İhtiyacınız olan her şey tek bir panelde. Hizmet alın ve kolayca yönetin.</p>
            </div>

            <div class="row mb-5">
              <div class="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
                <div class="search-form">
                  <div style="display: block; width: 776px;">
                    <input type="text" class="form-control input-lg keywords-input xdsoft_input" placeholder="Search Product....." autocomplete="off" style="font-size: 14px; background: white !important;">
                    <div class="xdsoft_autocomplete_dropdown" style="left: 0px; top: 46px; margin-left: 0px; margin-right: 0px; width: 776px;"></div>
                    <input placeholder="Bir Hizmet Arayın..." class="xdsoft_autocomplete_hint" style="box-sizing: border-box; border-style: solid; border-collapse: separate; border-width: 0px; padding: 10px 16px; margin: 0px; max-height: none; min-height: 0px; max-width: none; min-width: 0px; width: 776px; letter-spacing: normal; line-height: 18.6667px; outline-width: 0px; font-family: Roboto, sans-serif; font-variant: normal; font-style: normal; font-size: 14px; font-weight: 400; flex: 0 1 auto; justify-content: normal; border-radius: 4px; box-shadow: none; height: 46px; position: absolute; z-index: 1; border-color: transparent; outline-color: transparent; left: 0px; top: 0px; background: none 0% 0% / auto repeat scroll padding-box border-box rgb(255, 255, 255);"></div>
                  <button class="btn ripple "><i class="fe fe-search"></i></button>
                </div>
              </div>
            </div>

            <div class="temp-categories">
              <ul class="">
                <li>
                  <a href="#"><span>Tüm Hizmetlerimiz</span></a>
                </li>
                <li>
                  <a href="#"><span>Website</span></a>
                </li>
                <li>
                  <a href="#"><span>Grafik Tasarım</span></a>
                </li>
                <li>
                  <a href="#"><span>Video Editörlüğü</span></a>
                </li>
                <li>
                  <a href="#"><span>Mobil Uygulama</span></a>
                </li>
                <li>
                  <a href="#"><span>SEO</span></a>
                </li>
                <li>
                  <a href="#"><span>İçerik Editörlüğü</span></a>
                </li>
              </ul>
            </div>

          </div>
        </div><!-- /header-text -->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeHero"
}
</script>

<style scoped>

</style>