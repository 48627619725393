<template>
  <section class="sptb border-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-xl-6 col-md-12">
          <div class="sub-newsletter">
            <h3 class="mb-2 fs-20"><i class="fa fa-paper-plane-o mr-2"></i> Bültenimize Abone Olun</h3>
            <p class="mb-0">Güncel yazılardan ve hizmetlerimizden haberdar olmak isterseniz bültene ÜCRETSİZ bir şekilde abone olabilirsiniz.</p>
          </div>
        </div>
        <div class="col-lg-5 col-xl-6 col-md-12">
          <div class="input-group sub-input mt-1">
            <input type="text" class="form-control input-lg " placeholder="E-Posta Adresiniz">
            <div class="input-group-append ">
              <button type="button" class="btn ripple  btn-primary btn-lg br-tr-3  br-br-3">
                Abone Ol
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Newsletter"
}
</script>

<style scoped>

</style>