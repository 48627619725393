<template>
  <div class="header-main">
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-sm-4 col-7">
            <div class="top-bar-left d-flex">
              <div class="clearfix">
                <ul class="contact">
                  <li>
                    <a href="#" class="text-dark"><i class="fa fa-user-o"></i> Kullanıcı Paneli</a>
                  </li>
                  <li>
                    <a href="#" class="text-dark"><i class="fa fa-code"></i> Geliştirici Portalı</a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-sm-8 col-5">
            <div class="top-bar-right">
              <ul class="custom">
                <li>
                  <router-link :to="{name:'AuthRegister'}" class="text-dark"><i class="fa fa-plus mr-1"></i> <span> ÜCRETSİZ Kayıt Ol</span></router-link>
                </li>
                <li>
                  <router-link :to="{name:'AuthLogin'}" class="text-dark"><i class="fa fa-sign-in mr-1"></i> <span>Giriş Yap</span></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div><!--/Topbar-->

    <!--Header-->
    <header class="header-search header-logosec p-2 header-icons">
      <div class="container">
        <div class="d-flex align-items-center">
          <div class="header-search-logo d-none d-lg-block">
            <router-link class="header-logo header-brand-img" :to="{name:'Home'}"></router-link>
          </div>
          <div class="ml-auto">
            <a href="https://offer.simeray.com" target="_blank" class="btn btn-primary"><i class="fa fa-paper-plane"></i> Teklif Alın</a>
          </div>
        </div>
      </div>
    </header><!--/Header-->

    <!-- Mobile Header -->
    <div class="horizontal-header clearfix ">
      <div class="container">
        <a id="horizontal-navtoggle" class="animated-arrow"><span></span></a>
        <span class="smllogo"><img src="assets/images/brand/logo1.png" width="120" alt="img"/></span>
        <span class="smllogo-white"><img src="assets/images/brand/logo.png" width="120" alt="img"/></span>
        <a href="tel:245-6325-3256" class="callusbtn"><i class="fa fa-phone" aria-hidden="true"></i></a>
      </div>
    </div>
    <!-- /Mobile Header -->

    <!--Horizontal-main -->
    <div class="header-style horizontal-main bg-dark-transparent clearfix">
      <div class="horizontal-mainwrapper container clearfix">
        <nav class="horizontalMenu clearfix d-md-flex">
          <ul class="horizontalMenu-list">
            <li aria-haspopup="true">
              <router-link :to="{name:'Home'}">Anasayfa</router-link>
            </li>
            <li aria-haspopup="true">
              <router-link :to="{name:'ServicesList'}">Hizmetlerimiz</router-link>
            </li>
            <li aria-haspopup="true">
              <router-link :to="{name:'AboutUs'}">Hakkımızda</router-link>
            </li>
            <li aria-haspopup="true">
              <router-link :to="{name:'BlogList'}">Blog</router-link>
            </li>
            <li aria-haspopup="true"><a href="#">Kurumsal <span class="fe fe-chevron-down m-0"></span></a>
              <ul class="sub-menu">
                <li><router-link :to="{name:'Projects'}">Projelerimiz</router-link></li>
                <li><router-link :to="{name:'PagesTermsOfUse'}">Kullanım Koşulları</router-link></li>
                <li><router-link :to="{name:'PagesPrivacyPolicy'}">Gizlilik Politikası</router-link></li>
                <li><router-link :to="{name:'PagesDistanceSellingContract'}">Mesafeli Satış Sözleşmesi</router-link></li>
                <li><router-link :to="{name:'PagesCancellationAndRefundPolicy'}">İptal & İade Koşulları</router-link></li>
              </ul>
            </li>
            <li aria-haspopup="true">
              <router-link :to="{name:'ContactUs'}">Bize Ulaşın</router-link>
            </li>
            <li aria-haspopup="true"><a href="#">2 Alt Menü <span class="fe fe-chevron-down m-0"></span></a>
              <ul class="sub-menu">
                <li aria-haspopup="true"><a href="#">Alt Menü <i class="fa fa-angle-right float-right mt-1 d-none d-lg-block"></i></a>
                  <ul class="sub-menu">
                    <li aria-haspopup="true"><a href="blog-details.html">Alt Menünün Alt Menüsü</a></li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>