<template>
  <div :class="$route.name === 'Home' ? 'banner-1 cover-image bg-background2' : 'cover-image bg-background3'" data-image-src="assets/images/banners/banner1.jpg">
    <Header />
    <slot />
  </div>
</template>

<script>
import Header from "@/components/System/Header";

export default {
  name: "HeadContainer",
  components: {
    Header
  }
}
</script>

<style scoped>

</style>