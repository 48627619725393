<template>
  <section class="sptb">
    <div class="container">
      <div class="section-title center-block text-center">
        <h2>Newly Addded</h2>
        <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
      </div>
      <div class="row row-sm">
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/01.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/02.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/03.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/04.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/05.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/06.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/07.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/08.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/09.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/10.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/11.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
        <div class="col-3 col-md-2 col-lg-1">
          <a href="#" class="d-block link-overlay mb-2 shadow br-3 overflow-hidden">
            <img class="d-block img-fluid  mx-auto text-center w-100" src="assets/images/media/pictures/small/12.jpg" alt="">
            <span class="link-overlay-bg rounded">
								<i class="fa fa-search"></i>
							</span>
          </a>
        </div>
      </div>
      <div class="text-center mt-5">
        <a class="btn ripple  btn-primary text-white">View more</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NewlyAdded"
}
</script>

<style scoped>

</style>