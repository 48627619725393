<template>
  <section>
    <footer class="bg-dark text-white">
      <div class="footer-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <h6>Hakkımızda</h6>
              <hr class="deep-purple  accent-2 mb-4 mt-0 d-inline-block mx-auto">
              <p>Büyümek isteyen yenilikçi şirketler ile yetenekli iş ortaklarımızı ve kaliteli hizmetlerimizi bir araya getirmeyi hedefleyen yazılım geliştirme ve hizmet platformudur.</p>
              <ul class="list-unstyled list-inline mt-3">
                <li class="list-inline-item">
                  <a class="btn ripple btn-floating btn-sm mx-1" target="_blank" href="https://www.facebook.com/simeraycom">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn ripple btn-floating btn-sm mx-1" target="_blank" href="https://twitter.com/simeraycom">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn ripple btn-floating btn-sm mx-1" target="_blank" href="https://www.instagram.com/simeraycom/">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn ripple btn-floating btn-sm mx-1" target="_blank" href="https://www.linkedin.com/company/simeraycom">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a class="btn ripple btn-floating btn-sm mx-1" target="_blank" href="https://wa.me/message/257M7REIVQONN1">
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </li>
              </ul>

              <div class="row bg-white yt-area">
                <div class="col-md-12">
                  <div class="g-ytsubscribe" data-channelid="UCCOa9rRKb_dAzzoZNlE5ELg" data-layout="full" data-count="default"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-12">
              <h6>Kurumsal</h6>
              <hr class="deep-purple text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto">
              <ul class="list-unstyled mb-0">
                <li><router-link :to="{name:'AboutUs'}">Hakkımızda</router-link></li>
                <li><router-link :to="{name:'Projects'}">Projelerimiz</router-link></li>
                <li><router-link :to="{name:'ContactUs'}">Bize Ulaşın</router-link></li>
                <li><router-link :to="{name:'PagesTermsOfUse'}">Kullanım Koşulları</router-link></li>
                <li><router-link :to="{name:'PagesPrivacyPolicy'}">Gizlilik Politikası</router-link></li>
                <li><router-link :to="{name:'PagesDistanceSellingContract'}">Mesafeli Satış Sözleşmesi</router-link></li>
                <li><router-link :to="{name:'PagesCancellationAndRefundPolicy'}">İptal & İade Koşulları</router-link></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12">
              <h6>Hızlı Erişim</h6>
              <hr class="deep-purple text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto">
              <ul class="list-unstyled mb-0">
                <li><a href="#">Kullanıcı Paneli</a></li>
                <li><a href="#">Geliştirici Portalı</a></li>
                <li><a href="#">API Dökümantasyonları</a></li>
                <li><a href="#">Destek Portalı</a></li>
                <li><router-link :to="{name:'ServicesList'}">Hizmetlerimiz</router-link></li>
                <li><router-link :to="{name:'BlogList'}">Blog</router-link></li>
                <li><a href="https://offer.simeray.com" target="_blank">Teklif Alın</a></li>
              </ul>
            </div>

            <div class="col-lg-3 col-md-12">
              <h6>İletişim</h6>
              <hr class="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto">
              <ul class="footer-conatct list-unstyled mb-0 contact-footer">
                <li>
                  <a href="#"><i class="fa fa-home mr-3 text-white"></i>  İnternetin Olduğu Her Yerde...</a>
                </li>
                <li>
                  <a href="mailto:contact@simeray.com"><i class="fa fa-envelope mr-3 text-white"></i> contact@simeray.com</a></li>
                <li>
                  <a href="https://wa.me/message/257M7REIVQONN1"><i class="fa fa-whatsapp mr-3 text-white"></i> +90 212 909 9681</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-dark text-white-50 p-0">
        <div class="container">
          <div class="row d-flex">
            <div class="col-lg-12 col-sm-12 mt-3 mb-3 text-center">
              Copyright © 2018-2022 <a href="/" class="fs-14 text-primary">Simeray</a>. Tüm Hakları Saklıdır.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    let platformJS = document.createElement('script')
    platformJS.setAttribute('src', 'https://apis.google.com/js/platform.js')
    document.head.appendChild(platformJS)
  },
}
</script>

<style scoped>
.yt-area{
  padding-top: 10px;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}
</style>