<template>
  <section class="sptb">
    <div class="container">
      <div class="section-title center-block text-center">
        <h2><strong>Öne Çıkan Hizmetlerimiz</strong></h2>
        <p>Kullanıcılarımız tarafından en çok tercih edilen bazı hizmetleri kategorili olarak sizin için listeledik. Dilediğiniz gibi inceleyin.</p>
      </div>
      <div class="panel panel-primary">
        <div class="">
          <div class="tabs-menu ">
            <!-- Tabs -->
            <ul class="nav panel-tabs eductaional-tabs mb-6">
              <li class=""><a href="#tab-1" class="active show" data-toggle="tab">WordPress</a></li>
              <li><a href="#tab-2" data-toggle="tab" class="">Özel Yazılım</a></li>
              <li><a href="#tab-3" data-toggle="tab" class="">Logo</a></li>
              <li><a href="#tab-4" data-toggle="tab" class="">SEO</a></li>
              <li><a href="#tab-5" data-toggle="tab" class="">Mobil Uygulama</a></li>
              <li><a href="#tab-6" data-toggle="tab" class="">Grafik Tasarım</a></li>
            </ul>
          </div>
        </div>
        <div class="panel-body">
          <div class="tab-content p-0 pt-3">
            <div class="tab-pane  active show" id="tab-1">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
            <div class="tab-pane" id="tab-2">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
            <div class="tab-pane" id="tab-3">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
            <div class="tab-pane" id="tab-4">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
            <div class="tab-pane" id="tab-5">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
            <div class="tab-pane" id="tab-6">
              <div class="row">
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$15</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Gavin Fraser<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 36</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PSD</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$25</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/1.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Lily Paige<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Angular</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$23</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Carl Welch<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 24</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">PHP</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(3)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$32</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/female/2.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Joan Lyman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 25</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">Wordpress</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$21</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/3.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Jake Newman<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 33</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-md-6">
                  <div class="card overflow-hidden">
                    <div class="ribbon ribbon-top-left text-danger"><span class="bg-danger">Featured</span></div>
                    <div class="item-card2-tans">
                      <div class="item-card7-imgs">
                        <a href="page-details.html"></a>
                        <img src="assets/images/media/pictures/8.jpg" alt="img" class="cover-image">
                        <div class="tag-text">
                          <span class="bg-dark-transparent tag-option">HTML</span>
                        </div>
                      </div>
                      <div class="item-card2-icons">
                        <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Add to Wishlist"><i class="fe fe-heart"></i></a>
                        <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Buy Now"><i class="fe fe-shopping-cart"></i></a>
                        <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="item-card7-desc">
                        <div class="item-card7-text">
                          <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">GPR HTML Template</h4></a>
                          <p class="fs-13 mb-1 text-muted">Lorem Ipsum available, quis int lorem ipsum nostrum exercitationem </p>
                        </div>
                        <div class="d-md-flex">
                          <div class="rating-stars d-flex mr-5">
                            <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                            <div class="rating-stars-container mr-2">
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                              <div class="rating-star sm">
                                <i class="fa fa-star"></i>
                              </div>
                            </div>(4)
                          </div>
                          <div class="ml-auto">
                            <div class="item-card9-cost">
                              <h4 class=" mb-0">$24</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer pt-3">
                      <div class="footerimg d-flex mt-0 mb-0">
                        <div class="d-flex footerimg-l mb-0">
                          <img src="assets/images/users/male/12.jpg" alt="image" class="avatar avatar-sm brround  mr-2">
                          <a href="#" class="time-title text-muted p-0 leading-normal">Blake Mathis<i class="si si-check text-success fs-12 ml-1" data-toggle="tooltip" data-placement="top" title="verified"></i></a>
                        </div>
                        <div class="footerimg-r ml-auto">
                          <span class="text-muted"><i class="fe fe-shopping-cart"></i> 26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-3">
                <a href="ad-list.html" class="btn ripple  btn-primary btn-lg">View All Products</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LastServices"
}
</script>

<style scoped>

</style>