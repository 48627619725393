<template>
  <div id="global-loader">
    <img src="assets/images/loader.svg" class="loader-img" alt="img">
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>