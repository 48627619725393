<template>
  <section>
    <div class="about-1 cover-image sptb section-bg-background-color1">
      <div class="content-text mb-0 text-white info">
        <div class="container">
          <div class="row text-center">
            <div class="col-lg-3 col-md-6">
              <div class="counter-status md-mb-0">
                <div class="counter-icon">
                  <i class="fa fa-server"></i>
                </div>
                <h5 class="font-weight-normal">Toplam Hizmet</h5>
                <h2 class="counter mb-0">{{ $store.state.counter.totalServices }}</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="counter-status status-1 md-mb-0">
                <div class="counter-icon text-warning">
                  <i class="fa fa-folder-open-o"></i>
                </div>
                <h5 class="font-weight-normal">Toplam Proje</h5>
                <h2 class="counter mb-0">{{ $store.state.counter.totalProjects }}</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="counter-status status md-mb-0">
                <div class="counter-icon text-primary">
                  <i class="fa fa-code"></i>
                </div>
                <h5 class="font-weight-normal">Toplam Geliştirici</h5>
                <h2 class="counter mb-0">{{ $store.state.counter.totalDevelopers }}</h2>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="counter-status status">
                <div class="counter-icon text-success">
                  <i class="icon icon-emotsmile"></i>
                </div>
                <h5 class="font-weight-normal">Mutlu Kullanıcılar</h5>
                <h2 class="counter mb-0">{{ $store.state.counter.happyUsers }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Counter"
}
</script>

<style scoped>

</style>