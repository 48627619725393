<template>
  <section class="sptb">
    <div class="container">
      <div class="section-title center-block text-center">
        <h2><strong>Size Özel Hizmetler</strong></h2>
        <p>Hoşunuza gideceğini düşündüğümüz bazı hizmetleri sizin için listeledik.</p>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/1.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>34</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>456</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">Emily</a> in <a href="#" class="">HTML Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(4)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$15</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/2.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>24</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>123</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PSD Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">Piers</a> in <a href="#" class="">PSD Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(3)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$25</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/3.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>43</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>234</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Angular Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">Jessica</a> in <a href="#" class="">Angular Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(4)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$23</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden mb-xl-0">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/4.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>21</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>345</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">PHP Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">John</a> in <a href="#" class="">PHP Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="3">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(3)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$32</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden mb-md-0">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/5.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>45</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>108</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">Wordpress Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">John</a> in <a href="#" class="">Wordpress Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(4)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$21</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-6">
          <div class="card overflow-hidden mb-0">
            <div class="item-card2-tans">
              <div class="item-card7-imgs">
                <a href="page-details.html"></a>
                <img src="assets/images/media/pictures/7.jpg" alt="img" class="cover-image">
                <div class="tag-text">
                  <span class="bg-dark-transparent tag-option m-0"><i class="fe fe-download mr-1"></i>33</span>
                  <span class="bg-dark-transparent tag-option"><i class="fe fe-heart mr-1"></i>219</span>
                </div>
              </div>
              <div class="item-card2-icons">
                <a href="#" class="bg-primary" data-toggle="tooltip" data-placement="top" title="Live Preview"><i class="fe fe-airplay"></i></a>
                <a href="#" class="bg-secondary" data-toggle="tooltip" data-placement="top" title="Add to Cart"><i class="fe fe-shopping-cart"></i></a>
                <a href="#" class="bg-info" data-toggle="tooltip" data-placement="top" title="View Details"><i class="fe fe-eye"></i></a>
              </div>
            </div>
            <div class="card-body">
              <div class="item-card7-desc">
                <div class="item-card7-text">
                  <a href="page-details.html" class="text-dark"><h4 class="font-weight-semibold">HTML Template</h4></a>
                  <div class="mt-1 fs-13 mb-3">
                    by <a href="#" class="">Eric</a> in <a href="#" class="">HTML Templates</a>
                  </div>
                </div>
                <div class="d-md-flex">
                  <div class="rating-stars d-flex mr-5">
                    <input type="number" readonly="readonly" class="rating-value star" name="rating-stars-value" value="4">
                    <div class="rating-stars-container mr-2">
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                      <div class="rating-star sm">
                        <i class="fa fa-star"></i>
                      </div>
                    </div>(4)
                  </div>
                  <div class="ml-auto">
                    <div class="item-card9-cost">
                      <h4 class=" mb-0">$24</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RandomServices"
}
</script>

<style scoped>

</style>